import { CHANGE_STATISTICS_TIME_FILTER } from '../action_types';

export const initialState = {
  timeFilter: 3,
};

const defaultAction = { type: '', payload: [] };

function statisticsTimeFilter(state = initialState, action = defaultAction) {
  switch (action.type) {
    case CHANGE_STATISTICS_TIME_FILTER: {
      return Object.assign({}, state, {
        timeFilter: action.payload,
      });
    }
    default:
      return state;
  }
}

export default statisticsTimeFilter;
