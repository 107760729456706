import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from './store/reducers'; // tslint:disable-line

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ApolloClient from 'apollo-client'; // tslint:disable-line
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RetryLink } from 'apollo-link-retry';
// import { persistCache } from 'apollo-cache-persist';
import { ApolloProvider } from 'react-apollo';
import * as ReactGA from 'react-ga';
import * as Loadable from 'react-loadable';
import * as Raven from 'raven-js';
import 'typeface-roboto';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LoadingComponent from './components/LoadingComponent';
import withTracker from './utils/withTracker';
import './index.css';
import AuthorizedRoute from './components/AuthorizedRoute';
import * as fetch from 'unfetch'; //tslint:disable-line

let API_URL = 'https://staging-gvi.stageai.io/api';

if (process.env.REACT_APP_BUILD_ENV === 'production') {
  Raven.config('https://a07bea9fb14b494897a703072248bb4c@sentry.stageai.tech/4').install();
  ReactGA.initialize('UA-129785047-1'); // Google Analytics
  ReactGA.pageview(window.location.pathname + window.location.search); // Google Analytics
  API_URL = 'https://gvi.stageai.io/api';
}

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id,
});
// const storage = window.localStorage;

const retry = new RetryLink({ attempts : { max : Infinity } });
const authLink = setContext(async (req, { headers }) => {
  const token = await localStorage.getItem('userToken');
  return {
    ...headers,
    headers: { Authorization: token ? `Bearer ${token}` : null },
  };
});

// const waitOnCache = persistCache({ cache, storage });

const link = ApolloLink.from([
  retry,
  authLink,
  new HttpLink({
    uri: API_URL,
    fetchOptions: {
      fetch,
      credentials: 'include',
    },
  }),
]);

const client = new ApolloClient({
  link,
  cache,
});

const theme = createMuiTheme({
  palette: {
    primary: { main:'#5088D9' },
  },
  typography: {
    useNextVariants: true,
  },
});

const loading = () => <LoadingComponent />;

export const asyncLogin = Loadable({
  loading,
  loader: () => import('./views/LoginView'),
});

export const asyncRegister = Loadable({
  loading,
  loader: () => import('./views/RegisterView'),
});

export const asyncDashboard = Loadable({
  loading,
  loader: () => import('./views/DashboardView'),
});

export const asyncBills = Loadable({
  loading,
  loader: () => import('./views/BillsView'),
});

export const asyncForgottenPassword = Loadable({
  loading,
  loader: () => import('./views/ForgottenPassword'),
});

export const asyncChangePassword = Loadable({
  loading,
  loader: () => import('./views/ChangePassword'),
});

export const asyncProfile = Loadable({
  loading,
  loader: () => import('./views/ProfileView'),
});

export const asyncAdminListClients = Loadable({
  loading,
  loader: () => import('./views/AdminListClientsView'),
});

export const asyncSingleClientDashboard = Loadable({
  loading,
  loader: () => import('./views/SingleClientDashboard'),
});

export const asyncAdminListUsers = Loadable({
  loading,
  loader: () => import('./views/AdminListUsersView'),
});

export const asyncSingleUserView = Loadable({
  loading,
  loader: () => import('./views/SingleUserView'),
});

export const asyncAdminDashboardView = Loadable({
  loading,
  loader: () => import('./views/AdminDashboardView'),
});

const persistConfig = {
  storage,
  key: 'root',
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);

// waitOnCache.then(() => {
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistStore(store)}> */}
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={withTracker(asyncLogin)} />
              <Route path="/forgottenpassword" component={withTracker(asyncForgottenPassword)} />
              <Route path="/changePassword/:token" component={withTracker(asyncChangePassword)} />
              <AuthorizedRoute path="/" />
              <Redirect to="/login" />
            </Switch>
          </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    </ApolloProvider>
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement,
);
// });
