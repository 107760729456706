import { SINGLE_USER_INFORMATION } from '../action_types';

export const initialState = {
  userData: {},
};

const defaultAction = { type: '', payload: [] };

function saveUserData(state = initialState, action = defaultAction) {
  switch (action.type) {
    case SINGLE_USER_INFORMATION:
      return Object.assign({}, state, { userData: action.payload });
    default:
      return state;
  }
}

export default saveUserData;
