import { USER_LISTING_SUCCESS } from '../action_types';

export const initialState = {
  users: [],
}; // export for easy testing

const defaultAction = { type: '', payload: [] };

function userLisitng(state = initialState, action = defaultAction) {
  switch (action.type) {
    case USER_LISTING_SUCCESS: {
      const uniq = {};
      const removeDuplicates = Array.from(new Set([...state.users, ...action.payload]));
      const arrFiltered = removeDuplicates.filter((obj: any) => !uniq[obj.cursor] && (uniq[obj.cursor] = true));
      // console.log(arrFiltered);

      return Object.assign(
        {},
        state,
        state.users.length > 0 ?
          { users: arrFiltered } :
          { users: action.payload },
      );
    }
    default:
      return state;
  }
}

export default userLisitng;

