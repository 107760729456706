import { combineReducers } from 'redux';
import invoiceListing from './invoiceListing';
import userListing from './userListing';
import companyListing from './companyListing';
import userData from './userData';
import statisticsTimeFilter from './statisticsTimeFilter';

export default combineReducers({
  invoiceListing,
  userListing,
  companyListing,
  userData,
  statisticsTimeFilter,
});
