/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import * as React from 'react';
import * as ReactGA from 'react-ga';

interface PropsType {
  location: {
    pathname: string;
  };
}

export default <P extends object>(WRAPPED: React.ComponentType<P>, options = {}) => {
  const trackPage = (page: string) => {
    if (process.env.REACT_APP_BUILD_ENV === 'production') {
      ReactGA.set({
        page,
        ...options,
      });
      ReactGA.pageview(page);
    }
  };

  return class WithTracker extends React.Component<P & PropsType> {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps: any) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WRAPPED { ...this.props } />;
    }
  };
};
