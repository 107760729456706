export const USER_LISTING_REQUEST = 'user_listing_req';
export const USER_LISTING_SUCCESS = 'user_listing_suc';
export const USER_LISTING_FAIL = 'user_listing_fail';

export const COMPANY_LISTING_REQUEST = 'company_listing_req';
export const COMPANY_LISTING_SUCCESS = 'company_listing_suc';
export const COMPANY_LISTING_FAIL = 'company_listing_fail';

export const INVOICE_LISTING_REQUEST = 'invoice_listing_req';
export const INVOICE_LISTING_SUCCESS = 'invoice_listing_suc';
export const INVOICE_LISTING_FAIL = 'invoice_listing_fail';

export const INVOICE_REMOVE_REQUEST = 'invoice_remove_req';
export const INVOICE_REMOVE_SUCCESS = 'invoice_remove_suc';
export const INVOICE_REMOVE_FAIL = 'invoice_remove_fail';

export const SINGLE_USER_INFORMATION = 'single_user_information';

export const INVOICE_FILTERING = 'invoice_filtering';

export const CHANGE_STATISTICS_TIME_FILTER =
  'admin_dashboard_statistics_filter';
