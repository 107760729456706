import gql from 'graphql-tag'; // tslint:disable-line

export const CURRENTUSER = gql`
  query {
    currentUser {
      id
      email
      name
      role
      companies{
        id
        name
        address
        clientNumber
        eik
        mol
        balance
        balancedAt
      }
    }
  }
`;

export const GETCOMPANY = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      eik
      mol
      address
      balance
      balancedAt
      clientNumber
      contracts{
        contract
        users{
          id
          name
          email
          role
        }
      }
    }
  }
`;

export const GETCOMPANIES = gql`
  query GetCompanies($search: String) {
    getCompanies(search: $search) {
      id
      address
      name
      eik
      clientNumber
      contracts{
        contract
        users{
          id
          name
          email
          role
        }
      }
    }
  }
`;

export const GETINVOICES = gql`
  query GetInvoices($companies: [ID]!, $seen: Boolean) {
    getInvoices(companies: $companies, seen: $seen) {
      name
      bulstat
      number
      date
      amount
      pdf
      type
      status
    }
  }
`;

export const GETUSERS = gql`
  query GetUsers($search: String) {
    getUsers(search: $search) {
      id
      name
      email
      role
      companies{
        id
        name
        contracts{
          contract
          users{
            id
            name
            email
            role
          }
        }
      }
    }
  }
`;

export const GETUSER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      role
      companies{
        id
        name
        contracts{
          contract
          users{
            id
          }
        }
      }
    }
  }
`;

export const NEWENTRIES = gql`
  query NewEntries {
    newEntries {
      month
      companyCount
      userCount
      contractCount
      invoiceCount
    }
  }
`;

export const COUNTINVOICES = gql`
query CountInvoices {
  countInvoices {
    month
    paid
    unpaid
  }
}
`;

export const COMPANYLISTING = gql`
  query CompanyListing($first: Int, $after: String, $search: String) {
    companyListing(first: $first, after: $after, search: $search) {
      totalCount
      edges{
        node{
          id
          address
          name
          eik
          clientNumber
          contracts{
            contract
            users{
              id
              name
              email
              role
            }
          }
        }
        cursor
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const USERLISTING = gql`
  query UserListing($first: Int, $after: String, $search: String) {
    userListing(first: $first, after: $after, search: $search) {
      totalCount
      edges{
        node{
          id
          name
          email
          role
          companies{
            id
            name
            contracts{
              contract
              users{
                id
              }
            }
          }
        }
        cursor
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const INVOICELISTING = gql`
  query InvoiceListing($first: Int, $after: String, $search: String, $companyIDs: [ID]!, $seen: Boolean) {
    invoiceListing(first: $first, after: $after, search: $search, companyIDs: $companyIDs, seen: $seen) {
      totalCount
      edges{
        node{
          name
          bulstat
          number
          date
          amount
          pdf
          type
          status
        }
        cursor
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const AUDITLISTING = gql`
  query AuditListing($first: Int, $after: String, $userID: ID!, $action: Action) {
    auditListing(first: $first, after: $after, userID: $userID, action: $action) {
      totalCount
      edges{
        node{
          id
          userID
          action
          target
          rawData
          date
        }
        cursor
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
