import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import {
  asyncAdminDashboardView,
  asyncSingleClientDashboard,
  asyncAdminListClients,
  asyncAdminListUsers,
  asyncBills,
  asyncProfile,
  asyncSingleUserView,
} from './../../index';
import LoadingComponent from '../../components/LoadingComponent';
import { CURRENTUSER } from '../../utils/queries';
import withTracker from './../../utils/withTracker';

interface Props {
  path: string;
}
// tslint:disable-next-line
export const UserContext = React.createContext({
  id: '',
  name: '',
  email: '',
});

export default ({ path }: Props) => (
  <Query query={CURRENTUSER} fetchPolicy={'network-only'}>
    {({ loading, error, data }: any) => {
      return loading ? (
        <Grid container direction="column" justify="center" alignItems="center">
          <LoadingComponent />
        </Grid>
      ) : error ? (
        <Redirect to="/login" />
      ) : (
        <UserContext.Provider value={data.currentUser}>
          <Switch>
            {data.currentUser.role.toUpperCase() === 'ADMIN'
              ? [
                <Route
                    key={0}
                    path="/dashboard"
                    component={withTracker(asyncAdminDashboardView)}
                  />,
                <Route
                    key={1}
                    path="/profile"
                    component={withTracker(asyncProfile)}
                  />,
                <Route
                    key={2}
                    path="/bills"
                    component={withTracker(asyncBills)}
                  />,
                <Route
                    key={3}
                    path="/clients"
                    component={withTracker(asyncAdminListClients)}
                  />,
                <Route
                    key={4}
                    path="/users"
                    component={withTracker(asyncAdminListUsers)}
                  />,
                <Route
                    key={5}
                    path="/client/:id"
                    component={withTracker(asyncSingleClientDashboard)}
                  />,
                <Route
                    key={6}
                    path="/user/:id"
                    component={withTracker(asyncSingleUserView)}
                  />,
                <Route
                    key={7}
                    path="/"
                    component={withTracker(asyncAdminDashboardView)}
                  />,
              ]
              : data.currentUser.role.toUpperCase() === 'STAFF'
              ? [
                  // <Route key={0} path="/dashboard" component={withTracker(asyncBills)} />,
                <Route
                    key={0}
                    path="/bills"
                    component={withTracker(asyncBills)}
                  />,
                <Route
                    key={1}
                    path="/profile"
                    component={withTracker(asyncProfile)}
                  />,
                <Route
                    key={2}
                    path="/client/:id"
                    component={withTracker(asyncSingleClientDashboard)}
                  />,
                <Route key={3} path="/" component={withTracker(asyncBills)} />,
              ]
              : [
                  // <Route key={0} path="/dashboard" component={withTracker(asyncBills)} />,
                <Route
                    key={0}
                    path="/bills"
                    component={withTracker(asyncBills)}
                  />,
                <Route
                    key={1}
                    path="/profile"
                    component={withTracker(asyncProfile)}
                  />,
                <Route
                    key={2}
                    path="/client/:id"
                    component={withTracker(asyncSingleClientDashboard)}
                  />,
                <Route key={3} path="/" component={withTracker(asyncBills)} />,
              ]}
          </Switch>
        </UserContext.Provider>
      );
    }}
  </Query>
);
