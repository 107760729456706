import { INVOICE_LISTING_SUCCESS, INVOICE_REMOVE_SUCCESS, INVOICE_FILTERING } from '../action_types';

export const initialState = {
  invoices: [],
  prevQueryVariable: [],
  seen: null,
}; // export for easy testing

const defaultAction = { type: '', payload: [], newQueryVariable: [], seen: null };

function invoiceListing(state = initialState, action = defaultAction) {
  switch (action.type) {
    case INVOICE_LISTING_SUCCESS: {
      if (state.prevQueryVariable.toString() === action.newQueryVariable.toString()) {
        const uniq = {};
        const removeDuplicates = Array.from(new Set([...state.invoices, ...action.payload]));
        const arrFiltered = removeDuplicates.filter((obj: any) => !uniq[obj.cursor] && (uniq[obj.cursor] = true));
        return Object.assign(
          {},
          state,
          (state.invoices.length > 0 && action.payload.length > 0) ?
            { invoices: arrFiltered } :
            { invoices: action.payload },
        );
      } else { //tslint:disable-line
        return Object.assign(
          {},
          state,
          { invoices: action.payload, prevQueryVariable: action.newQueryVariable },
        );
      }
    }
    case INVOICE_FILTERING: {
      if (state.seen === action.seen) {
        const uniq = {};
        const removeDuplicates = Array.from(new Set([...state.invoices, ...action.payload]));
        const arrFiltered = removeDuplicates.filter((obj: any) => !uniq[obj.cursor] && (uniq[obj.cursor] = true));

        return Object.assign(
          {},
          state,
          (state.invoices.length > 0 && action.payload.length > 0) ?
            { invoices: arrFiltered } :
            { invoices: action.payload },
        );
      } else { //tslint:disable-line
        return Object.assign(
          {},
          state,
          { invoices: action.payload, seen: action.seen },
        );
      }
    }
    case INVOICE_REMOVE_SUCCESS: {
      return Object.assign(
        {},
        state,
        { invoices: state.invoices.filter((invoice: any) => (
          invoice.node.number !== action.payload
        )) },
      );
    }
    default:
      return state;
  }
}

export default invoiceListing;
