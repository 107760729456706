import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';

interface Props {
  classes?: any;
  loaderStyles?: any;
}

export default withStyles(styles)(({ classes, loaderStyles }: Props) => (
  <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress
      className={loaderStyles ? loaderStyles : classes.mainLoader}
      size={70}
    />
  </Grid>
));
